.navbar-dropdown-link {
  text-wrap: wrap;
  position: relative;
}

.navbar-dropdown-link::after {
  content: "";
  position: absolute;
  width: 43.2px;
  height: 2px;
  left: 0;
  top: 100%;
  background-color: black;
  transition: all ease-in-out 300ms;
}

.navbar-dropdown-link:hover::after {
  width: 100%;
  background-color: #D1A32F;
}