/* .footer-grid {
  grid-template-columns: 126px;
  grid-auto-rows: auto;
} */

.footer-grid {
  grid-template-columns: 101px 126px;
  grid-auto-rows: auto;
}

@media (min-width: 640px) {
  .footer-grid {
    grid-template-columns: 101px 126px;
    grid-auto-rows: auto;
  }
}

@media (min-width: 768px) {
  .footer-grid {
    grid-template-columns: 126px 68px 101px;
    grid-auto-rows: auto;
  }
}

@media (min-width: 874px) {
  .footer-grid {
    /* grid-template-columns: 126px 68px 101px; */
    grid-template-columns: 93px 68px 101px 126px;
    grid-auto-rows: auto;
  }
}

/* @media (min-width: 1024px) {
  .footer-grid {
    grid-template-columns: 93px 68px 101px 126px;
    grid-auto-rows: auto;
  }
} */